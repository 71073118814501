import React from 'react'
import { Link } from 'gatsby'
import { translate } from "react-i18next"


import Layout from '../components/layouts/baseLayout'
import Navbar from '../components/navbar/navbar'
import  indexStyle from "../styles/index.module.scss"

import uliege from "../images/index/uliege_logo_gris.png"
import liege from "../images/index/liege.png"
import chu from "../images/index/chu.png"
import cpas from "../images/index/cpas.png"
import helmo from "../images/index/helmo.png"

import logo_covoit from "../images/covoituliege_horizontal.svg"
import logo from "../images/logo_ugo.svg"
import logo_uliege from "../images/uliege_logo_normal.png"

import mobile_preview from "../images/index/mobile.png"
import tierugo from "../images/index/tier_ugo.svg"

import * as consts from "../const"

import applestore from "../images/index/appstore.svg"
import playstore from "../images/index/playstore.svg"

import backgroundillu from "../images/index/how_wide_illu.svg"
import backgroundilluextra from "../images/index/how_wide_extra.svg"

import one from "../images/index/step_1/one.svg"

import two from "../images/index/step_2/two.svg"

import three from "../images/index/step_3/three.svg"
import Form from './about'
import illuextra from '../images/index/how_wide_extra.svg'

function IndexPage (props) {
  const { t } = props;
  return (
  <Layout>


    <div className={indexStyle.home}>

      <div className={indexStyle.accueil}>
        <div className={indexStyle.introduction}>
            <h1>{t("bouchons")}<br/>{t("solution")}</h1>
            <p>{t("home_intro_one")}<br/>{t("home_intro_two")}</p>
            <div className={indexStyle.buttons}>
              <a className={indexStyle.button} href="/app/auth/registration">{t("home_connexion")}</a>
              <div className={indexStyle.appbuttons}>
                <a className={indexStyle.appbutton} href={consts.PlayStore}><img src={playstore} alt="bouton playstore"/></a>
                <a className={indexStyle.appbutton} href={consts.AppStore}> <img src={applestore} alt="bouton apple store"/></a>
              </div>
            </div>
            <p className={indexStyle.already} >{t("already_member")}<a className={indexStyle.link} href="/app/auth/login">{t("connexion")}</a></p>
        </div>
        <div className={indexStyle.partenaires}>
          <p>{t("partners")}</p>
          <div className={indexStyle.logo}>
            <img src={uliege} alt="logo de la ville de liège"/>
            <img src={chu} alt="logo du CHU"/>
            <img src={liege} alt="logo de la ville de liège"/>
            <img src={cpas} alt="logo du cpas"/>
            <img src={helmo} alt="logo de l'Helmo"/>
          </div>
        </div>
      </div>
    </div>

    <div className={indexStyle.homebottom}>
      <div className={indexStyle.wide}>

        <div className={indexStyle.howillu}>
          <img className={indexStyle.illustrationextra} src={backgroundilluextra} alt="personnes qui covoiturent"/>
          <img className={indexStyle.illustration} src={backgroundillu} alt="personnes qui covoiturent"/>
        </div>
      </div>
    </div>

    <section className={indexStyle.application}>
      <div className={indexStyle.bloc}>
        <h2>{t("pocket")}</h2>
        <p>{t("mobile_promo")}</p>
        <div className={indexStyle.appbuttons}>
          <a className={indexStyle.appbutton} href={consts.PlayStore}><img src={playstore} alt="bouton playstore"/></a>
          <a className={indexStyle.appbutton} href={consts.AppStore}><img src={applestore} alt="bouton apple store"/></a>
        </div>
        <img className={indexStyle.mobile} src={mobile_preview} alt="aperçu de l'application mobile"/>
        <img className={indexStyle.tierugo} src={tierugo} alt="personnage ugo"/>
      </div>
    </section>

    <main className={indexStyle.main}>
      <div className={indexStyle.one}>
        <div>
          <img className={indexStyle.picture} src={one} alt="site web"/>
        </div>
        <div className={indexStyle.text}>
          <h5>{t("step_one_cat")}</h5>
          <h3>{t("step_one_title")}</h3>
          <p>{t("step_one_text")}</p>
        </div>
      </div>

      <div className={indexStyle.two}>
        <div className={indexStyle.text}>
          <h5>{t("step_two_cat")}</h5>
          <h3>{t("step_two_title")}</h3>
          <p>{t("step_two_text")}</p>
        </div>
        <div>
          <img className={indexStyle.picture} src={two} alt="site web"/>
        </div>
      </div>

      <div className={indexStyle.three}>
        <div>
          <img className={indexStyle.picture} src={three} alt="site web"/>
        </div>
        <div className={indexStyle.text}>
          <h5>{t("step_three_cat")}</h5>
          <h3>{t("step_three_title")}</h3>
          <p>{t("step_three_text")} </p>
        </div>
      </div>
    </main>
  </Layout>)
}


export default translate("index")(IndexPage)
